import TableCSVExporter from '@/plugins/tabletocsv'
import {
    mapMutations
  } from 'vuex'
export const API_MIXINS = {
    methods: {
        ...mapMutations([
            'setAlert',
            'setAlertColor', 
            'setAlertText',
            'setGameSetting',
            'setOverlay',
            'setAllowStatBox',
            'setAllowSetUp',
            'setAdmin',
            'setAccountTransactions'
        ]),
        VA_ALERT(color, message) {
                this.setAlertText(message)
                this.setAlertColor(color)
                this.setAlert(true)
        },
        SET_PERMISSION(action){
          this.$session.get('role') == "Administrator"?this.setAdmin(true):this.setAdmin(false)
          if('game_set_up' == action) {
              this.$session.get('role') == "Administrator"?this.setAllowSetUp(true):this.setAllowSetUp(false)
          }
          if('game_stat_box'  == action) {
            this.$session.get('role') == "Administrator"?this.setAllowStatBox(true):this.setAllowStatBox(false)
         }
        },
        GET_ACCOUNT_TRANSACTIONS(query){
         // console.log(query)
          this.$http.post("wallet/get_transactions", query).then(response => {
           response.data.items != null?this.setAccountTransactions(response.data.items):this.setAccountTransactions([])
           //console.log(response.data.items)
         }).catch(e => {
           console.log(e.data)
         })
     },
        GET_GAME_SETTING(_code){
          console.log("codehere: ", _code)
            this.setOverlay(true)
            this.$http.post("game/get", {code:_code}).then(response => {
              console.log(response.data.game)
             response.data.game != null?this.setGameSetting(response.data.game):this.setGameSetting({})
             this.setOverlay(false)
           }).catch(e => {
             console.log(e.data)
           })
       },
       IS_ODD(num){
        if((this.$IsNum(num)%2) != 0) {
                return 'odd'
        }
        return 'even'
    },
      STATUS_COLOR(status){
         switch(status) {
            case "Add Load":
              return "text-success"
            case "Cash Out":
              return "text-warning"
            case "Buy Ticket":
              return "text-info"
            case "Drawn":
              return "text-accent"  
            case "Ready":
              return "text-info"
              case "Open":
                return "text-success"
            default:
              return ""
         }
      },
      TRANSACTION_DESCRIPTION(item){
        switch(item.type) {
           case "Buy Ticket":
             return "Buy Ticket"
             case "Transfer Load":
              return item.description
              case "Received Load":
                return item.description
             case "Cash Out":
              return item.description
           default:
             return item.type
        }
     },
      STATUS_ICON(status){
        if(status == "Add Load" || status == "Received Load" ) {
          return {icon: "mdi-plus-thick", color: "success"}
        }
        return {icon: "mdi-minus-thick", color: "error"}
     },
       DownloadToCsv (eleID, filename) {
        const dataTable = document.getElementById(eleID)
        const exporter = new TableCSVExporter(dataTable)
        const csvOutput = exporter.convertToCSV()
        const csvBlob = new Blob([csvOutput], { type: 'text/csv' })
        const blobUrl = URL.createObjectURL(csvBlob)
        const anchorElement = document.createElement('a')
  
        anchorElement.href = blobUrl
        anchorElement.download =  filename+ '.csv'
        anchorElement.click()
  
        setTimeout(() => {
          URL.revokeObjectURL(blobUrl)
        }, 500)
      }
    } ///eof methods
}