<template>
    <v-navigation-drawer
      v-model="$store.state.drawer"
      absolute
      dark
      id="va-drawer"
      color="primary"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="$store.state.applogo"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>GAME</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      
      <v-list dense>
      <v-list-item-group color="fff">
          <v-list-item  @click="$router.push('dashboard').catch(err => {})">
          <v-list-item-icon>
            <v-icon small>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mid-font">DASHBOARD</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
       <template v-if="ALLOWED_SET_UP">
        <v-subheader class="mid-font">GAMES</v-subheader>
        <v-list-item
          v-for="(item, i) in national_games"
         :key="i + '-national-games'"
          @click="clickLink(item)"
        >
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="mid-font"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-subheader class="mid-font">LOCAL GAMES</v-subheader> -->
        <!-- <v-list-item
          v-for="(item, i) in local_games"
         :key="i + '-local-games'"
         @click="clickLink(item)"
        >
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="mid-font"></v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-subheader class="mid-font">Accounts</v-subheader>
        <v-list-item
          v-for="(item, i) in accounts"
         :key="i + '-accounts'"
          @click="$router.push(item.page).catch(err => {})"
        >
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="mid-font"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
       </template>
        <v-subheader class="mid-font">REPORTS</v-subheader>
        <v-list-item
          v-for="(item, i) in reports"
         :key="i + '-reports'"
          @click="$router.push(item.page).catch(err => {})"
        >
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="mid-font"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>
    </v-list>


    </v-navigation-drawer>

</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data () {
      return {
        item: 1,
      accounts: [
          { title: 'Users', icon: 'mdi-account-key', page:'/users'},
          { title: 'Game Settings', icon: 'mdi-cogs', page:'/game-settings'}
        ],
        national_games: [
        { title: "Side Bets", icon: 'mdi-clipboard-list-outline', page:'/events'},
        {title: '2D',  game:'2D', icon: 'mdi-numeric-2-circle', page:'/2d-game-setup'},
        {title: '3D',  game:'3D', icon: 'mdi-numeric-3-circle', page:'/3d-game-setup'},
        { title: '4D',game:'4D', icon: 'mdi-numeric-4-circle', page:'/4d-game-setup'},
           // { title: '3D-ZAM-CITY', game:'3D-ZAM-CITY', icon: 'mdi-numeric-3-circle', page:'/3D-Zam-City'},
        ],
        local_games: [
          {title: ' STL-SILAY',   game:'STL-SILAY', icon: 'mdi-numeric-2-circle', page:'/2d-game-silay'},
          { title: '2D STL-ILOILO-CITY',  game:'2D-STL-ILOILO-CITY', icon: 'mdi-numeric-2-circle', page:'/2d-game-city'},
          { title: '3D STL-ILOILO-CITY',  game:'3D-STL-ILOILO-CITY', icon: 'mdi-numeric-3-circle', page:'/3d-game-city'},
          { title: 'STL-ILOILO-PROVINCE',  game:'STL-ILOILO-PROVINCE', icon: 'mdi-numeric-2-circle', page:'/2d-game-province'},
          { title: 'STL-ANTIQUE',  game:'STL-ANTIQUE', icon: 'mdi-numeric-2-circle', page:'/2d-game-antique'},
        
        ],
        games: [
           {title: '3D', icon: 'mdi-numeric-3-circle', page:'/3d-game-setup'},
          { title: '4D', icon: 'mdi-numeric-4-circle', page:'/4d-game-setup'},
          { title: 'LAST TWO', icon: 'mdi-numeric-2-circle', page:'/last-two-game-setup'},
            { title: 'PICK 3', icon: 'mdi-numeric-3-circle', page:'/pick3-game-setup'},
        ],
         reports: [
            { title: "BETS", icon: 'mdi-clipboard-list-outline', page:'/bet-logs'},
            { title: 'Reports', icon: 'mdi-chart-tree', page:'/reports'},
            { title: 'Buy Credits', icon: 'mdi-credit-card', page:'/buy-credits'},
            { title: 'Cashouts', icon: 'mdi-cash', page:'/cashouts'},
            { title: 'Voids', icon: 'mdi-cancel', page:'/void-tickets'}
        ],
        mini: true,
      }
    },
    computed: {
      ALLOWED_SET_UP() {
           if(this.$session.get('role') == "Administrator") {
              return true
           }
           return false
      }
    },
    methods: {
      ...mapMutations(['setDrawer', 'setGameSetup']),
      clickLink(item){
        console.log(item)
        this.setGameSetup(item.game)
        this.$router.push(item.page)
      }
    }
  }
</script>