<template>
    <v-dialog
       v-model="show"
       persistent
       fullscreen
       >
   
                <v-card class="ma-1">
                 <v-toolbar dense  class="pa-1" color="primary" dark>
                   Event Details
                   <!-- <v-chip color="success"> {{event.status}}</v-chip> -->
                    <v-spacer/>
                   <v-icon @click="emitToParent('cancel')">mdi-close-circle</v-icon>
                 </v-toolbar>
                 <v-card-text>
                     <div class="mt-2 pa-1">EVENT: {{event.name}}</div>
                     <v-divider/>
                     <div class="pa-1">DATE: {{$moment(event.date).format("MMM, DD YYYY")}}</div>
                     <v-divider/>
                     <div class="pa-1">VENUE: {{event.venue}}</div>
                     <v-divider/>
                     <div class="pa-1">STATUS: {{event.status}}</div>
                     <v-divider/>
                     <div class="pa-1 font-weight-bold">Overall GROSS: {{$money(TOTAL)}}</div>
                <v-divider/>
                <div class="pa-1 font-weight-bold">No. Of Bets: {{$count(bets.length)}}</div>
                <v-divider/>
                <!-- {{summaries}} -->
                     <table class="va-table mt-2 mb-4  va-table-border-bot" cellspacing="0">
                      <th class="text-left" style="background: #eaeaea; padding: 4px">Pick</th>
                           <th class="text-left" style="background: #eaeaea; padding: 4px">Bracket</th>
                           
                           <th class="text-left" style="background: #eaeaea; padding: 4px">No. of Bets</th>
                           <th class="text-left" style="background: #eaeaea; padding: 4px">No. Winners</th>
                           <th class="text-right" style="background: #eaeaea; padding: 4px">Gross</th>
                           <th class="text-right" style="background: #eaeaea; padding: 4px">Payout</th>
                      <tr v-for="(item, i) in summaries" :key="i">
                        <td>{{item.pick}}</td>
                        <td>{{item.bracket}}</td>
                        
                        <td>{{item.num_bets}}</td>
                        <td>{{item.winners}}</td>
                        <td class="text-right">{{$money(item.gross)}}</td>
                        <td class="text-right">{{$money(item.payout)}}</td>
                        </tr>
                      </table>
                     <!-- <div class="pa-1 font-weight-bold">PAYOUT: {{$money(getPayout())}} PHP</div>
                     <v-divider/> -->
                     <v-toolbar dense>
                      Bets
                      <v-spacer/>
                      <span style="width: 240px" class="mr-2">
                        <v-select hide-details dense label="Filter Picks" @change="bracket='ALL'" v-model="pick" outlined :items="PICKS"/>
                        
                      </span>
                      <span style="width: 240px">
                        <v-select 
                         hide-details
                         dense 
                         label="Filter Bracket" 
                         v-model="bracket" 
                         outlined 
                         item-text="group"
                         item-value="group"
                         :items="BRACKETS"/>
                      </span>
                        
                       
                     </v-toolbar>
                     <table class="va-table mt-1  va-table-border-bot" cellspacing="0">
                       <tr>
                           <th class="text-left" style="background: #eaeaea; padding: 4px">Acct. No</th>
                           <th class="text-left" style="background: #eaeaea; padding: 4px">Pick</th>
                           <th class="text-left" style="background: #eaeaea; padding: 4px">Bracket</th>
                           <th class="text-left" style="background: #eaeaea; padding: 4px">Amount</th>
                           <th class="text-left" style="background: #eaeaea; padding: 4px">({{FILTEREDBETS.length}})Entries</th>
                           <th class="text-left" style="background: #eaeaea; padding: 4px">Posted</th>
                           <th class="text-left" style="background: #eaeaea; padding: 4px">Status</th>
                         </tr>
                         <tr v-for="(item, i) in FILTEREDBETS" :key="i">
                             <td> {{item.account}}</td>
                             <td> {{item.pick}}</td>
                             <td> {{item.bracket}}</td>
                             <td> {{$money(item.amount)}}</td>
                             <td class="text-d">
                               <v-chip color="primary" label outlined class="mr-1" small v-for="(name, index) in parseEntries(item.entry_names)" :key="index">
                                   {{name}}
                               </v-chip>
                               </td>
                               <td> {{$moment(item.posted_at).format("MMM, DD YYYY hh:mm A")}}</td>
                               <td :class="item.status=='Win'?'text-success font-weight-bold':''"> {{item.status}}
                                <v-chip color="primary" small label v-if="event.status=='Completed'">
                                Score: {{item.score }}
                            </v-chip>
                              </td>
                         </tr>
                     </table>
                   </v-card-text>  
                </v-card>
            
   
    </v-dialog>
   </template>
   <script>
   import {
     mapMutations
   } from 'vuex'
     export default {
       props: {
         show: {
           type: Boolean,
           default: false
         },
          event_id: {
           type: Number,
           default: ()=>0
         },
        //  bracket: {
        //    type: String,
        //    default: ()=> ""
        //  },
        //  pick: {
        //    type: String,
        //    default: ()=> ""
        //  }
       },
       data: () => ({
         ticket: {},
         to_menu: false,
         viewticket: false,
         to_date:'',
         confirm: false,
         cancelbtn: 'No',
         okbtn: "Ok",
         confirmText: "",
         action:'',
         pick:"ALL",
         bracket: "ALL",
         event: {},
         summaries:[],
         bets:[],
         entries: []
       }),
       // created() {
       //   this.PING()
       // },
       //  mounted(){
       //    this.setLoggedIn(true)
       //     if (this.user == null) {
       //       this.$router.push('/')
       //     }else {
       //       this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
       //       this.to_date = this.$moment().format("YYYY-MM-DD")
       //       this.setData()
       //     }
       // },
       watch: {
         show(v) {
             if(v) {
               this.event = {}
               this.bets = []
               this.entries = []
               this.getEvent()
               this.getSummaries()
             }
         }
       },
       computed:{
         user() {
           if(localStorage.user){
               return JSON.parse(localStorage.user)
           }
           return null
         },
         BRACKETS() {
          let _brackets = this.event.brackets
        //  _brackets.unshift({bet:0, group:'ALL'})
          return _brackets
         },
         PICKS() {
          let _picks = this.event.picks
        //  _picks.unshift("ALL")
          return _picks
         },
         TOTAL(){
        var total = this.bets.reduce((res, item)=>{
             res = res + this.$IsNum(item.amount)
             return res
           }, 0)
          return total
      },
         FILTEREDBETS(){
          if(this.pick =='ALL') {
            return this.bets
          }

          var _bets = this.bets.reduce((res, item)=>{
             if(item.pick == this.pick) {
               res.push(item)
             }
             return res
           }, [])

           if(this.bracket =='ALL') {
            return _bets
          }
          var _fbets = _bets.reduce((res, item)=>{
             if(item.bracket == this.bracket) {
               res.push(item)
             }
             return res
           }, [])

           return _fbets
         },
         BYBRACKETS() {
           var allbets = this.$groupBy('bracket')
           return allbets(this.bets)
          },
          ALLBETS() {
           var _bets = this.bets.reduce((res, item)=>{
             if(item.pick == this.pick && item.bracket == this.bracket) {
               res.push(item)
             }
             return res
           }, [])
           return _bets
          },
         isOnline(){
           return this.$store.state.is_online
         }
       },
       methods: {
         ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
         emitToParent(){
           this.$emit('DialogEvent', "")
         },
         parseEntries(entries){
           return entries.split(',')
         },
         account(_id) {
           if(_id == this.user.id) {
             return "Your Bet"
           }
           return _id
         },
         getPayout() {
           var bets = this.getStats(this.bracket)
           var total = bets.reduce((res, item)=>{
             res =res + this.$IsNum(item.amount)  
             return res
           }, 0)
           return total * ((100 - this.$IsNum(this.event.percentage)))/100
          },
          getStats(_group) {
             for (let [key, value] of Object.entries(this.BYBRACKETS)) {
               if(_group == key) {
                  var picks = this.$groupBy('pick')
                  var _obj = picks(value)
                 return _obj[this.pick]?_obj[this.pick]:[]
               } 
             }
             return []
          },
         getEvent: function() { 
           this.$http.post("event/get", {id: this.event_id} ).then(response => {
            console.log(response.data)
               response.data.item != null?this.event = response.data.item:this.event = {}
               this.getEntries()
              
           }).catch(e => {
           console.log(e.data)
           })
         },
         getSummaries(){ 
          console.log("getSummaries")
           this.$http.post("event/summary", {id: this.event_id} ).then(response => {
            console.log(response.data)
               response.data.items != null?this.summaries = response.data.items:this.summaries = []
           }).catch(e => {
           console.log(e.data)
           })
        },
         getEntries(){ 
           this.$http.post("entry/get", {event_id: this.event_id} ).then(response => {
               response.data.items != null?this.entries = response.data.items:this.entries = []
               this.getBets('all')
           }).catch(e => {
           console.log(e.data)
           })
        },
        getBets(status){  
           this.$http.post("sidebet/qry", {event_id: this.event_id, status: status} ).then(response => {
               response.data.items != null?this.bets = response.data.items:this.bets = []
               console.log("getbet", this.bets)
           }).catch(e => {
           console.log(e.data)
           })
        },
        
       },
     }
   </script>