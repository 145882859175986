<template>
    <v-dialog
    v-model="show"
    persistent
    width="1020">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-gamepad-circle-up</v-icon><span class="va-underline">{{game}} KEY-IN FORM</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                        
                        <v-col cols="12">
                             <v-text-field
                             type="number"
                                v-model="draw_id"
                                label="Enter Draw ID"
                                outlined
                                large
                                :rules="nameRules"
                                 append-outer-icon="mdi-arrow-right"
                                 @click:append-outer="getResult"
                                required
                            ></v-text-field>
                        </v-col>
                        
                        <!-- 2d -->
                        <template v-if="game == '2D' || game == 'STL-ANTIQUE' || game == 'STL-SILAY' || game == '2D-STL-ILOILO-CITY' || game == 'STL-ILOILO-PROVINCE'">
                        <v-col cols="6">
                             <input 
                                type="number"
                                v-model="Result.num1"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 1"
                            />
                        </v-col>
                         <v-col cols="6">
                             <input 
                                type="number"
                                v-model="Result.num2"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 2"
                            />
                        </v-col>
                        
                        </template>
                         
                         <!-- 3D -->
                        <template v-if="game == '3D'  || game == '3D-STL-ILOILO-CITY' || game == '3D-ZAM-CITY'">
                        <v-col cols="4">
                            <input 
                                type="number"
                                v-model="Result.num1"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 1"
                            />
                        </v-col>
                         <v-col cols="4">
                              <input 
                                type="number"
                                v-model="Result.num2"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 2"
                            />
                        </v-col>
                         <v-col cols="4">
                              <input 
                                type="number"
                                v-model="Result.num3"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 3"
                            />
                        </v-col>
                        </template>
                         <template v-if="game == 'PICK3'">
                        <v-col cols="2">
                            <input 
                                type="number"
                                v-model="Result.num1"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="N1"
                            />
                        </v-col>
                         <v-col cols="2">
                              <input 
                                type="number"
                                v-model="Result.num2"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="N2"
                            />
                        </v-col>
                         <v-col cols="2">
                              <input 
                                type="number"
                                v-model="Result.num3"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="N3"
                            />
                        </v-col>
                         <v-col cols="2">
                            <input 
                                type="number"
                                v-model="Result.num4"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="N4"
                            />
                        </v-col>
                         <v-col cols="2">
                              <input 
                                type="number"
                                v-model="Result.num5"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="N5"
                            />
                        </v-col>
                         <v-col cols="2">
                              <input 
                                type="number"
                                v-model="Result.num6"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="N6"
                            />
                        </v-col>
                        </template>

                         <!-- 4D -->
                        <template v-if="game == '4D'">
                        <v-col cols="3">
                            <input 
                                type="number"
                                v-model="Result.num1"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 1"
                            />
                        </v-col>
                         <v-col cols="3">
                              <input 
                                type="number"
                                v-model="Result.num2"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 2"
                            />
                        </v-col>
                         <v-col cols="3">
                              <input 
                                type="number"
                                v-model="Result.num3"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 3"
                            />
                        </v-col>
                         <v-col cols="3">
                              <input 
                                type="number"
                                v-model="Result.num4"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 4"
                            />
                        </v-col>
                        </template>
                    
                       
                    </v-row>
               </v-form>
               <v-divider class="mb-4 mt-5"/>
          </v-card-text>
          <v-card-actions>
              <v-btn x-large color="warning" @click="emitToParent('close')">
                 close
              </v-btn>
              <v-spacer/>
              <v-btn x-large  color="success" :loading="loading" @click="validate()">
                 DRAW
              </v-btn>
          </v-card-actions>
    </v-card>
     <va-confirm :show="confirm" :message="CONFIRM_MESSAGE" okbtn="Confirm" cancelbtn="Cancel" @ConfirmEvent="cdEvent"/>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      game: {
          type: String,
          default:''
      }
  },
  data () {
      return {
          loading: false,
          draw_id: '',
          confirm: false,
          Result:{},
          pre_result:{},
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(v) {
         if(v) {
             this.Result = {}
             this.draw_id = ''
            // this.getResult() 
         }
     }
  },
  computed:{
      CONFIRM_MESSAGE() {
          var message = ''
          switch (this.game ){
              case '3D':
                 message=  this.Result.num1 +" - " + this.Result.num2+" - " + this.Result.num3
            break;
            case '3D-STL-ILOILO-CITY':
                 message=  this.Result.num1 +" - " + this.Result.num2+" - " + this.Result.num3
         break;
              case '3D-ZAM-CITY':
              message=  this.Result.num1 +" - " + this.Result.num2+" - " + this.Result.num3
             break;
           case '4D':
                  message = this.Result.num1 +" - " + this.Result.num2  +" - " + this.Result.num3 +" - " + this.Result.num4
          break;
            //   case 'PICK3':
            //     message=  this.Result.num1 +" - " + this.Result.num2+" - " + this.Result.num3 +" - " + this.Result.num4 +" - " + this.Result.num5 +" - " + this.Result.num6
            //  break;
            //   case 'LASTTWO':
            //      message = this.Result.num1 +" - " + this.Result.num2
          
            default:
                message = this.Result.num1 +" - " + this.Result.num2
          }
         return message
      }
  },
  methods: {
      cdEvent(data) {
          this.confirm = false
          if('ok' == data) {
              this.loading = true
              this.keyIn()
          }
      },
   emitToParent (action) {
      this.$emit('DialogEvent', {action:action})
    },
    validate(){
        if (this.$refs.form.validate()) {
           this.confirm =true
        }
    },
    assignResult(){
        this.Result.num1 = this.pre_result.result.num1
                 this.Result.num2 = this.pre_result.result.num2
                  this.Result.num3 = this.pre_result.result.num3
    },
    getResult(){
       this.Result = {}
         this.$http.post("game/get_result", {draw_id: this.$IsNum(this.draw_id)}).then(response => {
            response.data.data != null?this.pre_result = response.data.data:this.pre_result = {}
            if(response.data.data != null) {
                this.Result = this.pre_result.result
                
            }
            }).catch(e => {
                console.log(e)
            })
    },
    keyIn(){
        var param = {}
        param.game = this.game
        param.draw_id =  this.$IsNum(this.draw_id)
        
        if(this.game == '3D' || this.game == '3D-STL-ILOILO-CITY' || this.game == '3D-ZAM-CITY') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2),
                num3: this.$IsNum(this.Result.num3)
            }
        }
         if(this.game == '2D' || this.game == 'STL-SILAY' || this.game == 'STL-ANTIQUE' || this.game == '2D-STL-ILOILO-CITY' || this.game == 'STL-ILOILO-PROVINCE') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2)
            }
        }
         if(this.game == 'LASTTWO') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2)
            }
        }
         if(this.game == 'PICK3') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2),
                num3: this.$IsNum(this.Result.num3),
                num4: this.$IsNum(this.Result.num4),
                num5: this.$IsNum(this.Result.num5),
                num6: this.$IsNum(this.Result.num6)
            }
        }
         if(this.game == '4D') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2),
                num3: this.$IsNum(this.Result.num3),
                num4: this.$IsNum(this.Result.num4)
            }
        }
        
        
            this.$http.post("draw/key_in", param).then(response => {
            response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
             this.loading = false
           this.$emit('DialogEvent', {action:'close'})
            }).catch(e => {
                console.log(e)
            })
        
    }
  }
}
</script>
